import React, { Dispatch, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearStateAndHydrate, RootState } from "./redux/RootReducer";
import { Button, Col, Container, Row } from "reactstrap";
import { clearSpeaker, nextSpeaker } from "./redux/SpeakersSlice";
import { RouteComponentProps, Router } from "@reach/router";
import { TimerList } from "./Timer";
import { getSpeakerDisplay } from "./SpeakerUtils";
import { SpeakersList } from "./SpeakersList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addTimer } from "./redux/TimerSlice";
import { updateId } from "./redux/MetadataSlice";
import { Settings } from "./Settings";

interface Props extends RouteComponentProps {
  listId?: string;
  key?: string;
}

function SpeakerDisplay() {
  const dispatch = useDispatch();
  const numTimers = useSelector((state: RootState) => state.timers.length);
  const currentSpeaker = useSelector(
    (state: RootState) => state.speakersList.currentSpeaker
  );
  const numSpeakers = useSelector(
    (state: RootState) => state.speakersList.speakers.length
  );

  return (
    <Container className="CurrentSpeaker">
      <h1 className="display-4">{getSpeakerDisplay(currentSpeaker)}</h1>
      <TimerList />
      {renderNextSpeakerButton(
        dispatch,
        numSpeakers === 0 && currentSpeaker === ""
      )}
      {renderClearSpeakerButton(dispatch, currentSpeaker === "")}
      {
        <Button
          disabled={numTimers > 5}
          className="ControlButton"
          onClick={() => dispatch(addTimer())}
        >
          Add timer
        </Button>
      }
    </Container>
  );
}

function SpeakersListMain(props: Props) {
  return (
    <Row className="h-100">
      <Col sm={8}>
        <Row className="min-height-35">
          <SpeakerDisplay />
        </Row>
        <Row>
          <Container></Container>
        </Row>
      </Col>
      <Col sm={4}>
        <SpeakersList listId={props.listId} />
      </Col>
    </Row>
  );
}

export const SpeakersListComponent = (props: Props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateId(props.listId || ""));
  }, [props.listId, dispatch]);
  useEffect(() => {
    dispatch(clearStateAndHydrate(props.listId || ""));
  }, [props.listId, dispatch]);

  // This won't work on reach router links!
  const loaded = useSelector((state: RootState) => state.metadata.loaded);

  if (!loaded) {
    return (
      <Container className="LoadingContainer">
        <h1>
          <FontAwesomeIcon icon="spinner" spin /> Loading...
        </h1>
      </Container>
    );
  }

  return (
    <Router>
      <Settings path="settings" />
      <SpeakersListMain default listId={props.listId} />
    </Router>
  );
};

const renderNextSpeakerButton = (
  dispatch: Dispatch<any>,
  disabled: boolean
) => {
  return (
    <Button
      onClick={() => {
        dispatch(nextSpeaker());
        // dispatch(stopTimer());
      }}
      disabled={disabled}
      className="ControlButton"
    >
      Next speaker
    </Button>
  );
};

const renderClearSpeakerButton = (
  dispatch: Dispatch<any>,
  disabled: boolean
) => {
  return (
    <Button
      onClick={() => {
        dispatch(clearSpeaker());
      }}
      disabled={disabled}
      className="ControlButton"
    >
      Clear
    </Button>
  );
};
