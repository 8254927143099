import { getCountryFromAbbreviation } from "./CountryMap";
import { getEmojiFlag } from "countries-list";

export const getSpeakerDisplay = (
  speakerName?: string,
  defaultValue = "🇺🇳 Chairperson"
) => {
  if (speakerName) {
    const fullName = getCountryFromAbbreviation(speakerName);
    return `${getEmojiFlag(speakerName.toUpperCase())} ${fullName}`;
  }

  return defaultValue;
};
