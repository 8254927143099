import { SpeakersList } from "./SpeakersSlice";
import { Timer, TimerArray } from "./TimerSlice";
import { Metadata } from "./MetadataSlice";

export const speakersListSliceInitialState: SpeakersList = {
  speakers: [],
  currentSpeaker: "",
  reserveLists: []
};

export const singleTimerState: Timer = {
  endTime: 0,
  running: false,
  timeLeft: 300,
  defaultTime: 300
};

export const timerInitialState: TimerArray = [singleTimerState];

export const metadataInitialState: Metadata = {
  initialState: true,
  loaded: false,
  listId: "",
  listName: "Committee",
  availableCountries: []
};

export const initialState = {
  speakersList: speakersListSliceInitialState,
  timers: timerInitialState,
  metadata: metadataInitialState
};
