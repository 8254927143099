import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { speakersListSliceInitialState } from "./InitialState";
import { SpeakersList } from "../SpeakersList";

export interface SpeakersList {
  speakers: ListOfSpeakers;
  currentSpeaker: string;
  reserveLists: Array<ListOfSpeakers>;
}

type ListOfSpeakers = Array<string>;

interface ModifySpeaker {
  speakerId: number;
}

interface NewSpeaker {
  speaker: string;
}

const speakersListSlice = createSlice({
  initialState: speakersListSliceInitialState,
  reducers: {
    addSpeaker(state, action: PayloadAction<NewSpeaker>) {
      const { payload } = action;
      state.speakers.push(payload.speaker);
    },
    moveUp(state, action: PayloadAction<ModifySpeaker>) {
      if (action.payload.speakerId > 0) {
        swapElements(
          state.speakers,
          action.payload.speakerId,
          action.payload.speakerId - 1
        );
      }
    },
    moveDown(state, action: PayloadAction<ModifySpeaker>) {
      const { payload } = action;
      if (state.speakers.length > payload.speakerId + 1) {
        swapElements(state.speakers, payload.speakerId, payload.speakerId + 1);
      }
    },
    removeElement(state, action: PayloadAction<ModifySpeaker>) {
      const { payload } = action;
      if (payload.speakerId < state.speakers.length) {
        state.speakers.splice(payload.speakerId, 1);
      }
    },
    nextSpeaker(state) {
      state.currentSpeaker = state.speakers[0] || "";
      state.speakers.shift();
    },
    saveList(state) {
      if (state.speakers.length > 0) {
        state.reserveLists.push(state.speakers);
        state.speakers = [];
      }
    },
    getTopSavedList(state) {
      if (state.reserveLists.length > 0) {
        state.speakers = state.reserveLists.pop() || [];
      }
    },
    replaceState(_, action: PayloadAction<SpeakersList>) {
      return { ...action.payload, loaded: true };
    },
    clearSpeaker(state) {
      state.currentSpeaker = "";
    }
  },
  name: "speakersList"
});

function swapElements(a: Array<any>, first: number, second: number) {
  if (a.length === 1) return a;
  a.splice(second, 1, a.splice(first, 1, a[second])[0]);
  return a;
}

export const {
  addSpeaker,
  nextSpeaker,
  removeElement,
  saveList,
  getTopSavedList,
  replaceState,
  moveUp,
  moveDown,
  clearSpeaker
} = speakersListSlice.actions;

export default speakersListSlice.reducer;
