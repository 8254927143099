import { Action, configureStore } from "@reduxjs/toolkit";
import rootReducer, { FIRST_SAVED_KEY, RootState } from "./RootReducer";
import { ThunkAction } from "redux-thunk";
import localForage from "localforage";
import { throttle } from "lodash";
import { setInitialized } from "./MetadataSlice";
import moment from "moment";

const store = configureStore({ reducer: rootReducer });

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

async function setFirstSaved() {
  const firstSaved = await localForage.getItem<Number>(FIRST_SAVED_KEY);
  if (!firstSaved) {
    await localForage.setItem(FIRST_SAVED_KEY, moment().valueOf());
  }
}

store.subscribe(
  throttle(async () => {
    const state = store.getState();
    const listId = state.metadata.listId;
    const existingList = await localForage.getItem<RootState>(listId);
    if (!existingList || !state.metadata.initialState) {
      await localForage.setItem(listId, {
        ...state,
        metadata: { ...state.metadata, initialState: false }
      });
      if (state.metadata.initialState) {
        store.dispatch(setInitialized());
      }
    }

    await setFirstSaved();
  }, 250)
);

export default store;
