import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import {
  Button,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Input
} from "reactstrap";
import { padStart, debounce } from "lodash";
import { RouteComponentProps } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./redux/RootReducer";
import {
  deleteTimer,
  editDefaultTime,
  resetTimer,
  startTimer,
  stopTimer
} from "./redux/TimerSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  index: number;
}

interface TimerListProps extends RouteComponentProps {}

export const TimerList = (props: TimerListProps) => {
  const timers = useSelector((state: RootState) => state.timers);

  let countdowns = timers.map((_, i) => <Timer key={i} index={i} />);

  return <div className="Multiple-Timer-Container">{countdowns}</div>;
};

const Timer = (props: Props) => {
  const { index } = props;

  const dispatch = useDispatch();
  const endTime = useSelector(
    (state: RootState) => state.timers[index].endTime
  );
  const running = useSelector(
    (state: RootState) => state.timers[index].running
  );
  const timeLeft = useSelector(
    (state: RootState) => state.timers[index].timeLeft
  );
  const defaultTime = useSelector(
    (state: RootState) => state.timers[index].defaultTime
  );
  const [editing, setEditing] = useState(false);
  const [ticker, setTicker] = useState(false);

  const getTime = useCallback(() => {
    return running
      ? moment.duration(moment(endTime).diff(moment().utc())).asSeconds()
      : timeLeft;
  }, [running, timeLeft, endTime]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (running) {
        setTicker(!ticker);
        if (!isTimeLeft(getTime())) {
          dispatch(stopTimer({ index }));
        }
      }
    }, 500);
    return () => clearInterval(interval);
  }, [running, ticker, setTicker, dispatch, getTime, index]);

  function handleEdit() {
    if (editing) {
      setEditing(false);
    } else {
      setEditing(true);
      dispatch(stopTimer({ index }));
    }
  }

  function handleEditTime(newTime: number) {
    handleEdit();
    dispatch(editDefaultTime({ index, newTime }));
  }

  let time = getTime();
  let timeString = isTimeLeft(time) ? formatTimeLeft(time) : "0:00";

  return (
    <div className="Single-Timer-Container">
      {!editing && (
        <TimerDisplay onStartEdit={handleEdit} timeString={timeString} />
      )}
      {editing && (
        <EditTime onEnter={handleEditTime} defaultValue={defaultTime} />
      )}
      {!running && (
        <Button
          className="ControlButton"
          disabled={!isTimeLeft(getTime()) || editing}
          onClick={() => dispatch(startTimer({ index }))}
        >
          Start
        </Button>
      )}
      {running && (
        <Button
          className="ControlButton"
          onClick={() => dispatch(stopTimer({ index }))}
        >
          Stop
        </Button>
      )}
      <ResetButton
        disabled={editing}
        onClick={() => dispatch(resetTimer({ index }))}
        handleChangeTime={value => {
          dispatch(resetTimer({ index }));
          dispatch(editDefaultTime({ index, newTime: value }));
        }}
      />
      {index > 0 && (
        <Button
          className="ControlButton"
          onClick={() => dispatch(deleteTimer({ index }))}
        >
          Remove
        </Button>
      )}
      {/*<Button className="ControlButton" onClick={() => dispatch(addTimer())}>Add</Button>*/}
    </div>
  );
};

function formatTimeLeft(timeInSeconds: number) {
  const time = moment.duration(timeInSeconds, "seconds");
  const seconds = Math.ceil(time.asSeconds() % 60);
  const minutes = time.minutes() + (seconds === 60 ? 1 : 0);
  return `${minutes}:${formatSeconds(seconds === 60 ? 0 : seconds)}`;
}

function formatSeconds(seconds: number) {
  return padStart(String(seconds), 2, "0");
}

function isTimeLeft(time: number) {
  return time > 0;
}

function TimerDisplay(props: { onStartEdit: () => void; timeString: string }) {
  const [hoverTime, setHoverTime] = useState(false);
  const setHoverTimeDebounced = debounce(setHoverTime, 1000);

  return (
    <div className="Timer-Value-Container">
      <span className="Timer-Display" />
      <span
        className="Timer-Value"
        onMouseOver={() => setHoverTime(true)}
        onMouseLeave={() => setHoverTimeDebounced(false)}
      >
        <h1 className="display-3" onDoubleClick={props.onStartEdit}>
          {props.timeString}
        </h1>
      </span>
      <span className="Timer-Edit-Icon">
        {hoverTime && (
          <h1
            onMouseEnter={() => setHoverTimeDebounced(true)}
            onMouseLeave={() => setHoverTimeDebounced(false)}
            onClick={props.onStartEdit}
          >
            <FontAwesomeIcon icon="pencil-alt" />
          </h1>
        )}
      </span>
    </div>
  );
}

const ResetButton = (props: {
  disabled: boolean;
  onClick: () => void;
  handleChangeTime: (value: number) => void;
}) => {
  const [dropdownOpen, setOpen] = useState(false);

  const toggle = () => setOpen(!dropdownOpen);

  return (
    <ButtonDropdown
      isOpen={dropdownOpen}
      toggle={toggle}
      disabled={props.disabled}
    >
      <Button id="caret" onClick={props.onClick}>
        Reset
      </Button>
      <DropdownToggle caret />
      <DropdownMenu>
        <ResetTimeItem onClickHandler={props.handleChangeTime} seconds={30} />
        <ResetTimeItem onClickHandler={props.handleChangeTime} minutes={5} />
        <ResetTimeItem onClickHandler={props.handleChangeTime} minutes={15} />
        <ResetTimeItem onClickHandler={props.handleChangeTime} minutes={30} />
      </DropdownMenu>
    </ButtonDropdown>
  );
};

const ResetTimeItem = (props: {
  onClickHandler: (value: number) => void;
  minutes?: number;
  seconds?: number;
}) => {
  function calculateTime(minutes = 0, seconds = 0) {
    return minutes * 60 + seconds;
  }

  function calculateTimeString(minutes?: number, seconds?: number) {
    let timeString = "";
    timeString += minutes ? `${minutes} minutes` : "";
    timeString += minutes && seconds ? ", " : "";
    timeString += seconds ? `${seconds} seconds` : "";
    return timeString;
  }

  return (
    <DropdownItem
      onClick={() =>
        props.onClickHandler(calculateTime(props.minutes, props.seconds))
      }
    >
      {calculateTimeString(props.minutes, props.seconds)}
    </DropdownItem>
  );
};

interface EditTimeProps {
  defaultValue: number;
  onEnter: (value: number) => void;
}

const EditTime = (props: EditTimeProps) => {
  const [time, setTime] = useState(
    moment.duration(props.defaultValue, "seconds")
  );

  function handleChangeSeconds(e: React.FormEvent<HTMLInputElement>) {
    const value = Number(e.currentTarget.value);
    if (Number.isNaN(value)) {
      return;
    }

    setTime(moment.duration(time.minutes() * 60 + value, "seconds"));
  }

  function handleChangeMinutes(e: React.FormEvent<HTMLInputElement>) {
    const value = Number(e.currentTarget.value);
    if (Number.isNaN(value)) {
      return;
    }

    setTime(moment.duration(time.seconds() + value * 60, "seconds"));
  }

  return (
    <Form inline className="EditTime">
      <h1>
        <Input
          onChange={handleChangeMinutes}
          type="number"
          value={time.minutes()}
          min={0}
          max={600}
        />
        :
        <Input
          type="number"
          onChange={handleChangeSeconds}
          min={-1}
          value={formatSeconds(time.seconds())}
          max={60}
        />
        <Button
          className="ControlButton"
          onClick={() => props.onEnter(time.seconds() + time.minutes() * 60)}
        >
          Save
        </Button>
      </h1>
    </Form>
  );
};

export default Timer;
