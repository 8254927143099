import React from "react";
import "./App.scss";
import { RouteComponentProps, Router } from "@reach/router";
import { TimerList } from "./Timer";
import { SpeakersListComponent } from "./SpeakersListOverall";
import { Container } from "reactstrap";
import DynamicNavBar from "./DynamicNavBar";

const App: React.FC = () => {
  return (
    <div>
      <DynamicNavBar />
      <Container fluid className="MainContainer h-100">
        <Router>
          <SpeakersListComponent listId="default" path="/" />
          <TimerList path="/countdown" />
          <TimerList path="/countdown/:initTime" />
          <SpeakersListComponent path="/:listId/*" />
          <NotFound default />
        </Router>
      </Container>
    </div>
  );
};

const NotFound = (props: RouteComponentProps) => {
  return <div>404!</div>;
};

export default App;
