import { AnyAction, combineReducers, createAction } from "@reduxjs/toolkit";
import speakersSlice from "./SpeakersSlice";
import metadataSlice, { setLoaded } from "./MetadataSlice";
import timerSlice from "./TimerSlice";
import { AppThunk } from "./Store";
import localForage from "localforage";

const appReducer = combineReducers({
  speakersList: speakersSlice,
  timers: timerSlice,
  metadata: metadataSlice
});

export type RootState = ReturnType<typeof appReducer>;

export const hydrateStore = createAction<RootState>("HYDRATE_STORE");
export const FIRST_SAVED_KEY = "firstSaved";

async function clearDataIfInvalid() {
  const firstSaved = await localForage.getItem<Number>(FIRST_SAVED_KEY);
  if (firstSaved && firstSaved < 1576206331000) {
    await localForage.clear();
  }
}

export const clearStateAndHydrate = (listId: string): AppThunk => {
  return async dispatch => {
    await clearDataIfInvalid();

    const existingState = await localForage.getItem<RootState>(listId);
    if (existingState) {
      dispatch(hydrateStore(existingState));
    }
    dispatch(setLoaded());
  };
};

const rootReducer = (state: RootState | undefined, action: AnyAction) => {
  return hydrateStore.match(action)
    ? action.payload
    : appReducer(state, action);
};

export default rootReducer;
