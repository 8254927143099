import { Button, Input, Navbar, NavbarBrand } from "reactstrap";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./redux/RootReducer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { updateName } from "./redux/MetadataSlice";
import { Link } from "@reach/router";

const DynamicNavBar = () => {
  const navTitle = useSelector(
    (state: RootState) => state.metadata.listName || state.metadata.listId
  );
  const listId = useSelector((state: RootState) => state.metadata.listId);
  const dispatch = useDispatch();
  const [hovering, setHovering] = useState(false);
  const [editing, setEditing] = useState(false);

  function handleSave(value: string) {
    dispatch(updateName(value));
    setEditing(false);
    setHovering(false);
  }

  return (
    <Navbar color="dark" dark className="MainNavBar">
      {!editing && (
        <NavbarBrand
          href=""
          onClick={e => {
            e.preventDefault();
          }}
          onMouseOver={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}
        >
          {`Speakers' List: ${navTitle || "Home"}`}
          {hovering && navTitle && (
            <FontAwesomeIcon
              onClick={() => setEditing(true)}
              className="Edit-List-Name-Icon"
              icon="pencil-alt"
            />
          )}
        </NavbarBrand>
      )}
      {editing && <EditListName defaultValue={navTitle} onSave={handleSave} />}
      {navTitle && (
        <ul className="nav navbar-nav navbar-right">
          <li>
            <Link to={`/${listId}/settings`}>
              <FontAwesomeIcon className="Nav-Settings-Icon" icon="cog" />
            </Link>
          </li>
        </ul>
      )}
    </Navbar>
  );
};

const EditListName = (props: {
  defaultValue: string;
  onSave: (value: string) => void;
}) => {
  const [value, setValue] = useState(props.defaultValue);

  return (
    <NavbarBrand
      onClick={e => {
        e.preventDefault();
      }}
      href=""
      className="Edit-List-Container"
    >
      Speakers' List:
      <Input
        bsSize="sm"
        onKeyDown={e => {
          if (e.key === "Enter") {
            props.onSave(value);
          }
        }}
        value={value}
        type="text"
        maxLength={50}
        onChange={e => setValue(e.currentTarget.value)}
      />
      <Button onClick={() => props.onSave(value)} size="sm">
        Save
      </Button>
    </NavbarBrand>
  );
};

export default DynamicNavBar;
