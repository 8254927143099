import React, { useCallback } from "react";
import { Link, RouteComponentProps } from "@reach/router";
import { Col, Row } from "reactstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./redux/RootReducer";
import { optionList } from "./data/CountryOptions.json";
import { updateAvailableSpeakers } from "./redux/MetadataSlice";

interface Props extends RouteComponentProps {
  listId?: string;
  key?: string;
}

interface CountryOption {
  label: string;
  value: string;
}

export const Settings = (props: Props) => {
  const availableCountries = useSelector(
    (state: RootState) => state.metadata.availableCountries
  );
  const dispatch = useDispatch();

  const filteredOptionList = useCallback(() => {
    const countrySet = new Set(availableCountries);
    return optionList
      .filter(element => countrySet.has(element.value))
      .sort((first, second) => (first > second ? 1 : 2));
  }, [availableCountries]);

  return (
    <Row className="Settings-Container">
      <Col sm={1}>
        <h4>
          <Link to={`/${props.listId}`}>← Back</Link>
        </h4>
      </Col>
      <Col sm={10} className="Settings-Column">
        <h4>Select countries:</h4>
        <Select
          value={filteredOptionList() as CountryOption[]}
          isMulti
          onChange={options => dispatch(updateAvailableSpeakers(options))}
          options={optionList as CountryOption[]}
          closeMenuOnSelect={false}
        />
      </Col>
      <Col sm={1} />
    </Row>
  );
};
