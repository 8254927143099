import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import React, { ReactText, useState } from "react";

interface Props {
  acceptFunction: () => void;
  warningText: string;
  children: ReactText | ReactText[];
  bypass?: boolean;
  disabled?: boolean;
}

export const ButtonWithConfirm = (props: Props) => {
  const [isOpen, setOpen] = useState(false);

  function toggle() {
    setOpen(!isOpen);
  }

  function triggerOrToggle() {
    if (props.bypass) {
      props.acceptFunction();
    } else {
      toggle();
    }
  }

  function confirm() {
    props.acceptFunction();
    toggle();
  }

  return (
    <span className="ButtonWithConfirm">
      <Button disabled={props.disabled} onClick={triggerOrToggle}>
        {props.children}
      </Button>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Confirm action</ModalHeader>
        <ModalBody>{props.warningText}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={confirm}>
            Confirm
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </span>
  );
};
