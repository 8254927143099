import moment from "moment";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { singleTimerState, timerInitialState } from "./InitialState";

export interface Timer {
  timeLeft: number;
  endTime: number;
  running: boolean;
  defaultTime: number;
}

export type TimerArray = Array<Timer>;

function endTimeToTimeLeft(endTime: number): number {
  return moment.duration(moment(endTime).diff(moment().utc())).asSeconds();
}

export interface TimerActionPayload {
  index: number;
}

export interface TimerUpdateTime extends TimerActionPayload {
  newTime: number;
}

const timerSlice = createSlice({
  initialState: timerInitialState,
  reducers: {
    startTimer(state, action: PayloadAction<TimerActionPayload>) {
      const { index } = action.payload;
      if (!state[index].running) {
        state[index].running = true;
        state[index].endTime = moment()
          .add(state[index].timeLeft, "seconds")
          .valueOf();
      }
    },
    stopTimer(state, action: PayloadAction<TimerActionPayload>) {
      const { index } = action.payload;
      if (state[index].running) {
        state[index].running = false;
        state[index].timeLeft = endTimeToTimeLeft(state[index].endTime);
      }
    },
    resetTimer(state, action: PayloadAction<TimerActionPayload>) {
      const { index } = action.payload;
      state[index].running = false;
      // state.endTime = moment().add(state.defaultTime, "seconds").valueOf();
      state[index].timeLeft = state[index].defaultTime;
    },
    tick(state, action: PayloadAction<TimerActionPayload>) {
      const { index } = action.payload;
      state[index].timeLeft = moment
        .duration(moment(state[index].endTime).diff(moment().utc()))
        .asSeconds();
    },
    addTimer(state) {
      if (state.length < 6) {
        state.push(singleTimerState);
      }
    },
    deleteTimer(state, action: PayloadAction<TimerActionPayload>) {
      const { index } = action.payload;
      if (index !== 0) {
        state.splice(index, 1);
      }
    },
    editDefaultTime(state, action: PayloadAction<TimerUpdateTime>) {
      const { index, newTime } = action.payload;
      state[index].defaultTime = newTime;
      state[index].timeLeft = newTime;
    }
  },
  name: "Timers"
});

export const {
  startTimer,
  stopTimer,
  resetTimer,
  editDefaultTime,
  tick,
  deleteTimer,
  addTimer
} = timerSlice.actions;

export default timerSlice.reducer;
