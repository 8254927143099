import { ValueType } from "react-select";

export interface OptionType {
  label: string;
  value: string;
}

/**
 * Resolves a ValueType into an array of OptionType.
 * @param value The ValueType to resolve.
 */
export function resolve(value: ValueType<OptionType>): OptionType[] {
  let optionTypes: OptionType[];
  if (value == null) {
    optionTypes = [];
  } else if (Array.isArray(value)) {
    optionTypes = value;
  } else {
    optionTypes = [value as OptionType];
  }
  return optionTypes;
}
