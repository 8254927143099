import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialState } from "./InitialState";
import { ValueType } from "react-select";
import { OptionType, resolve } from "../ReactSelectFix";

export interface Metadata {
  initialState: boolean;
  loaded: boolean;
  listId: string;
  listName: string;
  availableCountries: Array<string>;
}

const metadataSlice = createSlice({
  name: "metadata",
  initialState: initialState.metadata,
  reducers: {
    setLoaded(state) {
      state.loaded = true;
    },
    setInitialized(state) {
      state.initialState = false;
    },
    updateId(state, action: PayloadAction<string>) {
      state.listId = action.payload;
    },
    updateName(state, action: PayloadAction<string>) {
      state.listName = action.payload;
    },
    updateAvailableSpeakers(
      state,
      action: PayloadAction<
        ValueType<OptionType>[] | ValueType<OptionType> | undefined
      >
    ) {
      if (Array.isArray(action.payload)) {
        state.availableCountries = action.payload.map(
          element => resolve(element)[0].value
        );
      }
    }
  }
});

export const {
  setLoaded,
  setInitialized,
  updateName,
  updateId,
  updateAvailableSpeakers
} = metadataSlice.actions;

export default metadataSlice.reducer;
