import { useDispatch, useSelector } from "react-redux";
import React, { useMemo, useState } from "react";
import Select, { ValueType } from "react-select";
import { OptionType, resolve } from "./ReactSelectFix";
import { RootState } from "./redux/RootReducer";
import {
  addSpeaker,
  getTopSavedList,
  moveDown,
  moveUp,
  removeElement,
  saveList
} from "./redux/SpeakersSlice";
import { Button, Container } from "reactstrap";
import { ButtonWithConfirm } from "./ButtonWithConfirm";
import { optionList } from "./data/CountryOptions.json";
import { getSpeakerDisplay } from "./SpeakerUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RouteComponentProps } from "@reach/router";

interface CountryOption {
  label: string;
  value: string;
}

interface SpeakersListItem {
  index: number;
}

interface Props extends RouteComponentProps {
  listId?: string;
  key?: string;
}

export const SpeakersList = (props: Props) => {
  const dispatch = useDispatch();
  const [input, setInput] = useState<ValueType<OptionType>>();
  const speakers = useSelector(
    (state: RootState) => state.speakersList.speakers,
    (left, right) => left.length === right.length
  );
  const availableCountries = useSelector(
    (state: RootState) => state.metadata.availableCountries
  );
  const reserveListCount = useSelector(
    (state: RootState) => state.speakersList.reserveLists.length
  );

  const list = speakers.map((speaker, key) => (
    <ListItem index={key} key={key} />
  ));

  function handleAdd() {
    if (!input) {
      return;
    }
    dispatch(addSpeaker({ speaker: resolve(input)[0].value }));
    setInput(null);
  }

  function RenderButtons() {
    return (
      <>
        <Button
          className="ControlButton"
          onClick={handleAdd}
          disabled={input === undefined}
        >
          Add country
        </Button>
        <Button
          className="ControlButton"
          disabled={speakers.length === 0}
          onClick={() => dispatch(saveList())}
        >
          New secondary list
        </Button>
        <ButtonWithConfirm
          disabled={reserveListCount === 0}
          bypass={speakers.length === 0}
          acceptFunction={() => dispatch(getTopSavedList())}
          warningText="Are you sure to want to restore the previous list? This will delete the current list. This action cannot be undone!"
        >
          Restore list ({reserveListCount})
        </ButtonWithConfirm>
      </>
    );
  }

  const filteredOptionList = useMemo(() => {
    console.log("filtered options");
    if (availableCountries.length === 0) {
      return optionList;
    }
    const countrySet = new Set(availableCountries);
    return optionList.filter(element => countrySet.has(element.value));
  }, [availableCountries]);

  return (
    <Container className="SpeakersList">
      <h2>Speakers</h2>
      <ul>{speakers.length > 0 ? list : "No speakers!"}</ul>

      <Select
        value={input}
        options={filteredOptionList as CountryOption[]}
        onMenuOpen={() => setInput(undefined)}
        onKeyDown={e => {
          if (e.key === "Enter") {
            handleAdd();
          }
        }}
        placeholder="Select a country..."
        onChange={option => setInput(option)}
        ignoreAccents={false}
      />
      {RenderButtons()}
    </Container>
  );
};

const ListItem = (props: SpeakersListItem) => {
  const { index } = props;
  const dispatch = useDispatch();
  const speakerName = useSelector(
    (state: RootState) => state.speakersList.speakers[index]
  );
  const actionPayload = { speakerId: index };
  return (
    <li>
      <h3>
        {getSpeakerDisplay(speakerName)}
        <small>
          <FontAwesomeIcon
            icon="arrow-up"
            onClick={() => dispatch(moveUp(actionPayload))}
            className="ActionIcon"
          />
          <FontAwesomeIcon
            icon="arrow-down"
            onClick={() => dispatch(moveDown(actionPayload))}
            className="ActionIcon"
          />
          <FontAwesomeIcon
            icon="times"
            onClick={() => dispatch(removeElement(actionPayload))}
            className="ActionIcon"
          />
        </small>
      </h3>
    </li>
  );
};
